.fm-menu .list-group a {
	font-size: 16px;
	color: #5f5f5f;
	display: flex;
	align-items: center;
  
	i {
	  font-size: 23px;
	}
  
	&:hover {
	  background: #8833ff;
	  color: #fff;
	  transition: all 0.2s ease-out;
	}
  }
  
  .fm-file-box {
	font-size: 25px;
	background: #e9ecef;
	width: 44px;
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.25rem;
  }
  
  .fm-icon-box {
	font-size: 32px;
	background: #fff;
	width: 52px;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: center;
  
	//border-radius: .25rem
  }