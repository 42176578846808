
/* Background Colors */

.bg-white-2 {


	background-color: rgb(255 255 255 / 12%) !important;


}


.bg-light-primary {


	background-color: rgb(136 51 255 / 15%) !important;


}


.bg-light-success {


	background-color: rgb(41 204 57 / 15%)!important;


}


.bg-light-danger {


	background-color: rgb(230 46 46 / 15%)!important;


}


.bg-light-warning {


	background-color: rgb(255 193 7 / 0.11)!important;


}


.bg-light-info {


	background-color: rgb(13 202 240 / 18%)!important;


}


.bg-gradient-cosmic {


	background: linear-gradient(to right, rgb(142, 45, 226), rgb(74, 0, 224)) !important;


}


.bg-gradient-burning {


	background: linear-gradient(to right, rgb(255, 65, 108), rgb(255, 75, 43))!important;


}


.bg-gradient-lush {


	background: linear-gradient(to right, rgb(86, 171, 47), rgb(168, 224, 99))!important;


}


.bg-gradient-Ohhappiness {

	background: linear-gradient(to right, rgb(0, 176, 155), rgb(150, 201, 61))!important;


}


.bg-gradient-kyoto {


	background: linear-gradient(to right, rgb(247, 151, 30), rgb(255, 210, 0))!important;


}


.bg-gradient-blues {


	background: linear-gradient(to right, rgb(86, 204, 242), rgb(47, 128, 237))!important;


}


.bg-gradient-moonlit {


	background: linear-gradient(to right, rgb(15, 32, 39), rgb(32, 58, 67), rgb(44, 83, 100))!important;


}


.split-bg-primary {


	background-color: #7122e0;


	border-color: #7122e0;


}


.split-bg-secondary {


	background-color: #515a62;


	border-color: #515a62;


}


.split-bg-success {


	background-color: #1db52c;


	border-color: #1db52c;


}


.split-bg-info {


	background-color: #0bb2d3;


	border-color: #0bb2d3;


}


.split-bg-warning {


	background-color: #e4ad07;


	border-color: #e4ad07;


}


.split-bg-danger {


	background-color: #e20e22;


	border-color: #e20e22;


}


.bg-facebook {


	background-color: #3b5998!important;


}


.bg-twitter {


	background-color: #55acee!important;


}


.bg-google {


	background-color: #e52d27!important;


}


.bg-linkedin {


	background-color: #0976b4!important;


}


.text-sky-light {

	color: #cba6ff;

}