.product-show {
	font-size: 18px;
	left: 15px;
  }
  
  .product-discount {
	width: 2.5rem;
	height: 2.5rem;
	font-size: 14px;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
  }
  
  .color-indigator-item {
	width: 1.2rem;
	height: 1.2rem;
    background-color: rgb(230, 224, 224);
	border-radius: 50%;
	cursor: pointer;
  }
  
  .product-grid .card {
	-webkit-transition: all .2s;
	-o-transition: all .2s;
	transition: all 0.2s;
  }
  
  @media (min-width: 992px) {
	.product-grid .card:hover {
	  margin-top: -.25rem;
	  margin-bottom: .25rem;
	  -webkit-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
	  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
	}
  }

  .dash-wrapper {
    margin: -1.5rem -1.5rem -5.5rem -1.5rem;
    padding: 1.5rem 1.5rem 6.5rem 1.5rem;
	}

  
  .icon-badge {
	width: 45px;
	height: 45px;
	background: #f2f2f2;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
  }
  
  .widgets-icons {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ededed;
	font-size: 26px;
	border-radius: 10px;
  }
  
  #geographic-map {
	width: 100%;
	height: 350px;
  }
  
  .product-img {
	width: 45px;
	height: 45px;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	border: 1px solid #e6e6e6;
  
	img {
	  width: 45px;
	  height: 45px;
	  padding: 1px;
	}
  }
  
  .product-list {
	position: relative;
	height: 450px;
  }
  
  .dashboard-top-countries {
	position: relative;
	height: 330px;
  }
  
  .customers-list {
	position: relative;
	height: 450px;
  }
  
	.store-metrics {
		position: relative;
		height: 450px;
	}
	
	.best-selling-products {
		position: relative;
		height: 450px;
	}

	.recent-reviews {
	position: relative;
	height: 450px;
	}

	.support-list {
		position: relative;
		height: 450px;
	}
  .product-list .row {
	background-color: #f8f9fa;
	-webkit-transition: all .2s;
	-o-transition: all .2s;
	transition: all 0.2s;
  }
  
  @media (min-width: 992px) {
	.product-list .row:hover {
	  background-color: #fff;
	  margin-top: -.25rem;
	  margin-bottom: .25rem;
	  -webkit-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2);
	  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);
	}
  }
  
  .recent-product-img {
	width: 40px;
	height: 40px;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	border: 1px solid #e6e6e6;
  
	img {
	  width: 40px;
	  height: 40px;
	  padding: 6px;
	}
  }
  
  .theme-icons {
	background-color: #fff;
  }
  
  .lead-table .table {
	border-collapse: separate;
	border-spacing: 0 10px;
  }
  
  .user-plus {
	width: 33px;
	height: 33px;
	margin-left: -14px;
	line-height: 33px;
	background: #fff;
	border-radius: 50%;
	text-align: center;
	font-size: 22px;
	cursor: pointer;
	border: 1px dotted #a9b2bb;
	color: #404142;
  }
  
  .user-groups img {
	margin-left: -14px;
	border: 1px solid #e4e4e4;
	padding: 2px;
	cursor: pointer;
  }
  
  .contacts-social a {
	font-size: 16px;
	width: 36px;
	height: 36px;
	line-height: 36px;
	background: #fff;
	border: 1px solid #eeecec;
	text-align: center;
	border-radius: 50%;
	color: #2b2a2a;
  }
  
  .customers-contacts a {
	font-size: 16px;
	width: 34px;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	border: 1px solid #eeecec;
	text-align: center;
	border-radius: 50%;
	color: #2b2a2a;
  }
  
  .order-actions a {
	font-size: 18px;
	width: 34px;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f1f1f1;
	border: 1px solid #eeecec;
	text-align: center;
	border-radius: 20%;
	color: #2b2a2a;
  }
  
  .customers-list .customers-list-item {
	-webkit-transition: all .2s;
	-o-transition: all .2s;
	transition: all 0.2s;
  }
  
  @media (min-width: 992px) {
	.customers-list .customers-list-item:hover {
	  background-color: #f8f9fa;
	  border-radius: 10px;
	  margin-top: -.25rem;
	  margin-bottom: .25rem;
	  -webkit-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2);
	  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);
	}
  }
  
  .todo-done {
	text-decoration: line-through;
  }
  
  .chart-container1 {
	position: relative;
	height: 340px;
  }
  
  .gmaps, .gmaps-panaroma {
	height: 400px;
	background: #eee;
	border-radius: 3px;
  }
  
  input::placeholder {
	color: #000 !important;
	opacity: 0.3 !important;
  }
  
  .card-group {
	margin-bottom: 1.5rem;
  }

  .border-light-2 {
    border-color: rgb(255 255 255 / 12%)!important;
}
.accordion-item {
     background-color: transparent;
}
.accordion-button {
    background-color: transparent;
}


/* circle chart */


.circles {
	margin-bottom: -10px;
  }
  
  .circle {
	width: 100px;
	margin: 0;
	display: inline-block;
	position: relative;
	text-align: center;
	line-height: 1.2;
  }
  
  .circle canvas {
	vertical-align: middle;
  }
  
  .circle strong {
	position: absolute;
	top: 30px;
	left: 0;
	width: 100%;
	text-align: center;
	line-height: 40px;
	font-size: 24px;
  }
  
  .circle strong i {
	font-style: normal;
	font-size: 0.6em;
	font-weight: normal;
  }
  
  .circle span {
	display: block;
	color: #aaa;
	margin-top: 12px;
  }
