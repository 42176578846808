/* Header */

.topbar {
	position: fixed;
	top: 0;
	left: 250px;
	right: 0;
	height: 60px;
	background: #ffffff;
	border-bottom: 1px solid rgb(228 228 228 / 0%);
	background-clip: border-box;
	z-index: 10;
	box-shadow: 0 0.125rem 0.25rem rgb(136 152 170 / 15%);

	.navbar {
	  width: 100%;
	  height: 60px;
	  padding-left: 30px;
	  padding-right: 30px;
	}
  }

  .search-bar {
	display: none;
  }

  .full-search-bar {
	display: flex;
	align-items: center;
	width: auto;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 100;
	background: #ffffff;
	border-bottom: 1px solid rgb(228 228 228 / 0%);
	background-clip: border-box;
	height: 61px;
	padding: 0rem 1.4rem;
  }

  .search-bar-box {
	width: 100%;
  }

  .search-close {
	font-size: 24px;
	right: 10px;
	cursor: pointer;
  }

  .search-control {
	background-color: #fff;
	border: 1px solid #f2efef00;
	padding-left: 2.5rem;

	&:focus {
	  border: 1px solid #ffffff;
	  box-shadow: none;
	}
  }

  .search-show {
	font-size: 18px;
	left: 15px;
  }

  .topbar {
	.top-menu-left .nav-item .nav-link {
	  padding-right: 0.5rem;
	  padding-left: 0.5rem;
	  color: #252323;
	  font-size: 22px;
	}

	.navbar .navbar-nav .nav-link {
	  padding-right: 0.8rem;
	  padding-left: 0.8rem;
	  color: #252323;
	  font-size: 22px;
	}
  }

  .dropdown-toggle-nocaret:after {
	display: none;
  }

  .alert-count {
	position: absolute;
	top: 5px;
	left: 22px;
	width: 18px;
	height: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	font-size: 12px;
	font-weight: 500;
	color: #fff;
	background: #f62718;
  }

  .user-img {
	width: 42px;
	height: 42px;
	border-radius: 50%;
	border: 0px solid #e5e5e5;
	padding: 0px;
  }

  .user-info {
	.user-name {
	  font-size: 15px;
	  font-weight: 500;
	  color: #ffffff;
	}

	.designattion {
	  font-size: 13px;
	  color: #ffffff;
	}
  }

  .user-box {
	display: flex;
	align-items: center;
	height: 60px;
	margin-left: 1rem;
  }

  .dropdown-large {
	position: relative;

	.dropdown-menu {
	  width: 360px;
	  border: 0;
	  padding: 0rem 0;
	  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	}
  }

  .topbar .navbar {
	.dropdown-large .dropdown-menu::after {
	  content: '';
	  width: 13px;
	  height: 13px;
	  background: #ffffff;
	  position: absolute;
	  top: -6px;
	  right: 16px;
	  transform: rotate(45deg);
	  border-top: 1px solid #ddd;
	  border-left: 1px solid #ddd;
	}

	.dropdown-menu::after {
	  content: '';
	  width: 13px;
	  height: 13px;
	  background: #ffff;
	  position: absolute;
	  top: -6px;
	  right: 16px;
	  transform: rotate(45deg);
	  border-top: 1px solid #ddd;
	  border-left: 1px solid #ddd;
	}
  }

  .dropdown-large {
	.msg-header {
	  padding: 0.8rem 1rem;
	  border-bottom: 1px solid #ededed;
	  background-clip: border-box;
	  background: #ffffff;
	  text-align: left;
	  display: flex;
	  align-items: center;
	  border-top-left-radius: .25rem;
	  border-top-right-radius: .25rem;

	  .msg-header-title {
		font-size: 15px;
		color: #1c1b1b;
		margin-bottom: 0;
		font-weight: 500;
	  }

	  .msg-header-clear {
		font-size: 12px;
		color: #585858;
		margin-bottom: 0;
	  }
	}

	.msg-footer {
	  padding: 0.8rem 1rem;
	  color: #1c1b1b;
	  border-top: 1px solid #ededed;
	  background-clip: border-box;
	  background: transparent;
	  font-size: 14px;
	  font-weight: 500;
	  border-bottom-left-radius: .25rem;
	  border-bottom-right-radius: .25rem;
	}

	.user-online {
	  position: relative;
	}

	.msg-name {
	  font-size: 14px;
	  margin-bottom: 0;
	}

	.msg-info {
	  font-size: 13px;
	  margin-bottom: 0;
	}

	.msg-avatar {
	  width: 45px;
	  height: 45px;
	  border-radius: 50%;
	  margin-right: 15px;
	}

	.msg-time {
	  font-size: 12px;
	  margin-bottom: 0;
	  color: #919191;
	}

	.user-online:after {
	  content: '';
	  position: absolute;
	  bottom: 1px;
	  right: 17px;
	  width: 8px;
	  height: 8px;
	  border-radius: 50%;
	  box-shadow: 0 0 0 2px #fff;
	  background: #16e15e;
	}

	.dropdown-menu .dropdown-item {
	  padding: .50rem 1.3rem;
	  border-bottom: 1px solid #ededed;
	}
  }

  .header-message-list, .header-notifications-list {
	position: relative;
	height: 360px;
  }

  .dropdown-large .notify {
	width: 45px;
	height: 45px;
	line-height: 45px;
	font-size: 22px;
	text-align: center;
	border-radius: 50%;
	background-color: #f1f1f1;
	margin-right: 15px;
  }

  .app-box {
	width: 45px;
	height: 45px;
	display: flex;
	align-items: center;
	font-size: 26px;
	justify-content: center;
	cursor: pointer;
	border-radius: 10px;
	background-color: #f6f6f6;
  }

  .app-title {
	font-size: 14px;
  }

  .user-box .dropdown-menu i {
	vertical-align: middle;
	margin-right: 10px;
  }

  .dropdown-menu {
	-webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	border: 0px solid #e9ecef;
	font-size: 14px;
  }

  .topbar .navbar .dropdown-menu {
	-webkit-animation: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 animdropdown;
	animation: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 animdropdown;
  }

  @-webkit-keyframes animdropdown {
	from {
	  -webkit-transform: translate3d(0, 6px, 0);
	  transform: translate3d(0, 6px, 0);
	  opacity: 0;
	}

	to {
	  -webkit-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	  opacity: 1;
	}
  }

  @keyframes animdropdown {
	from {
	  -webkit-transform: translate3d(0, 6px, 0);
	  transform: translate3d(0, 6px, 0);
	  opacity: 0;
	}

	to {
	  -webkit-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	  opacity: 1;
	}
  }

  .mobile-search-icon {
	display: block;
  }

  .mobile-toggle-menu {
	display: none;
	font-size: 26px;
	color: #404142;
	cursor: pointer;
  }
