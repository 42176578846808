.btn {
	letter-spacing: 0.5px;
	i {
	  vertical-align: middle;
	  font-size: 1.3rem;
	  margin-top: -1em;
	  margin-bottom: -1em;
	  margin-right: 5px;
	}
  }

  .btn-facebook {
	box-shadow: 0 2px 2px 0 rgba(59, 89, 152, 0.14), 0 3px 1px -2px rgba(59, 89, 152, 0.2), 0 1px 5px 0 rgba(59, 89, 152, 0.12);
	background-color: #3b5998;
	border-color: #3b5998;
	color: #fff;

	&:hover {
	  color: #fff;
	}
  }

  .btn-white {
	background-color: #fff;
	border-color: #e7eaf3;
  }


.btn-group-round button:first-child {

    border-top-left-radius: 10px;

    border-bottom-left-radius: 10px;

}

.btn-group-round button:last-child {

    border-top-right-radius: 10px;

    border-bottom-right-radius: 10px;

}

.btn-xs
{
    padding: 2px 5px !important;
    font-size: 11px !important;
    line-height: 1.5 !important;
    border-radius: 3px !important;
}
