/* You can add global styles to this file, and also import other style files */

.btn-brand {
  background-color: #2f55d4;
  color: #ffffff;
}

.btn-brand:hover {
  background-color: #0061A8;
  color: #ffffff;
}

.link-brand {
  color: #2f55d4 !important;
}

.text-red {
  color: red;
}
