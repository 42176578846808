.breadcrumb-title {
	font-size: 20px;
	border-right: 1.5px solid #aaa4a4;
  }

  .page-breadcrumb {
	.breadcrumb li.breadcrumb-item {
	  font-size: 10px;
	}

	.breadcrumb-item + .breadcrumb-item::before {
	  display: inline-block;
	  padding-right: .4rem;
    padding-left: .4rem;
	  color: #6c757d;
    font-family: 'LineIcons';
	  content: "\ea5c";
	}
}
