.imageuploadify {
	border: 2px dashed #d2d2d2;
	position: relative;
	min-height: 350px;
	min-width: 250px;
	max-width: 1000px;
	margin: auto;
	display: flex;
	padding: 0;
	flex-direction: column;
	text-align: center;
	background-color: #fff;
	color: #007bff
}
.imageuploadify .imageuploadify-overlay {
	z-index: 10;
	width: 100%;
	height: 100%;
	position: absolute;
	flex-direction: column;
	top: 0;
	left: 0;
	display: none;
	font-size: 7em;
	background-color: rgba(242, 242, 242, .7);
	text-align: center;
	pointer-events: none
}
.imageuploadify .imageuploadify-overlay i {
	z-index: 10;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none
}
.imageuploadify .imageuploadify-images-list {
	display: inline-block
}
.imageuploadify .imageuploadify-images-list i {
	display: block;
	font-size: 7em;
	text-align: center;
	margin-top: .5em;
	padding-bottom: 12px
}
.imageuploadify .imageuploadify-images-list span.imageuploadify-message {
	font-size: 24px;
	border-top: 1px solid #007bff;
	border-bottom: 1px solid #007bff;
	padding: 10px;
	display: inline-block
}
.imageuploadify .imageuploadify-images-list button.btn-default {
	display: block;
	color: #007bff;
	border-color: #007bff;
	border-radius: 1em;
	margin: 25px auto;
	width: 100%;
	max-width: 500px
}
.imageuploadify .imageuploadify-images-list .imageuploadify-container {
	width: 100px;
	height: 100px;
	position: relative;
	overflow: hidden;
	margin-bottom: 1em;
	float: left;
	border-radius: 12px;
	box-shadow: 0 0 4px 0 #888
}
.imageuploadify .imageuploadify-images-list .imageuploadify-container button.btn-danger {
	position: absolute;
	top: 3px;
	right: 3px;
	width: 20px;
	height: 20px;
	border-radius: 15px;
	font-size: 10px;
	line-height: 1.42;
	padding: 2px 0;
	text-align: center;
	z-index: 3
}
.imageuploadify .imageuploadify-images-list .imageuploadify-container img {
	height: 100px;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: auto
}
.imageuploadify .imageuploadify-images-list .imageuploadify-container .imageuploadify-details {
	position: absolute;
	top: 0;
	padding-top: 20px;
	width: 100%;
	height: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	background: rgba(255, 255, 255, .5);
	z-index: 2;
	opacity: 0
}
.imageuploadify .imageuploadify-images-list .imageuploadify-container .imageuploadify-details span {
	display: block
}