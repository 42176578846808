


.nav-tabs .nav-link {
    color: #495057;
}

.nav-pills .nav-link {
    border-radius: .25rem;
    color: #495057;
}

.nav-primary.nav-tabs .nav-link.active {


	color: #2f55d4;


	border-color: #2f55d4 #2f55d4 #fff;


}


.nav-danger.nav-tabs .nav-link.active {


	color: #e62e2e;


	border-color: #e62e2e #e62e2e #fff;


}


.nav-success.nav-tabs .nav-link.active {


	color: #29cc39;


	border-color: #29cc39 #29cc39 #fff;


}


.nav-warning.nav-tabs .nav-link.active {


	color: #ffc107;


	border-color: #ffc107 #ffc107 #fff;


}


.nav-pills-danger.nav-pills .nav-link.active {


	color: #fff;


	background-color: #e62e2e;


}


.nav-pills-success.nav-pills .nav-link.active {


	color: #fff;


	background-color: #29cc39;


}


.nav-pills-warning.nav-pills .nav-link.active {


	color: #000;


	background-color: #ffc107;


}


.nav-search input.form-control {


	background-color: rgb(255 255 255 / 20%);


	border: 1px solid rgb(255 255 255 / 45%);


	color: #fff;


}


.nav-search button[type='submit'] {


	background-color: rgb(255 255 255 / 20%);


	border: 1px solid rgb(255 255 255 / 32%);


	color: #fff;


}


.nav-search input.form-control::placeholder {


	opacity: 0.5 !important;


	color: #fff !important;


}


.nav-search input.form-control::-ms-input-placeholder {


	color: #fff !important;


}
